import React, { Component } from 'react'

export class ModifyEnv extends Component {
    render() {
        return (
            <div>
                <p>This page is for modifying an Interview Environment</p>
            </div>
        )
    }
}

export default ModifyEnv
