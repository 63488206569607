import React, { Component } from 'react'

export class CandidateProfile extends Component {
    render() {
        return (
            <div>
                <p>This is the page where a recruiter can view a specific candidate in more details</p>
            </div>
        )
    }
}

export default CandidateProfile
