import React, { Component } from 'react'

export class CandidateReel extends Component {
    render() {
        return (
            <div>
                <p>This page is for the list of candidates for a given interview environment</p>
            </div>
        )
    }
}

export default CandidateReel
