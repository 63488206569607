import React, { Component } from 'react'

export class NewEnv extends Component {
    render() {
        return (
            <div className="newenv">
                <div className="newenv__container">
                    <h2 className="newenv__container-heading">Profile Overview Page for Users</h2>
                </div>
            </div>
        )
    }
}

export default NewEnv
