import React, { Component } from 'react';
import './SideNav.scss'

export class SideNav extends Component {

    render() {
        return (
            // Simply a side bar available on each page - will add additional styling later
            <div className="sidenav">
            </div>
        )
    }
}

export default SideNav
